.header-left {
  height: 100%;
  display: flex;
  align-items: center;
  .breadcrumb {
    margin-bottom: 0;
  }
  .agenda {
    a {
      border-radius: 1.75rem;
      i {
        margin-right: 0.75rem;
        @include respond("laptop") {
          margin-right: 0rem;
        }
      }
      @include custommq($max: 93.75rem) {
        font-size: 0;
        border-radius: 0.75rem;
        padding: 0.8125rem 0.9375rem;
        i {
          font-size: 1.25rem;
          margin-right: 0;
        }
      }
    }
    @include respond("phone") {
      display: none;
    }
  }
  .dashboard_bar {
    font-size: 1.75rem;
    font-weight: 600;
    color: var(--secondary);
    @include respond("tab-land") {
      font-size: 2.125rem;
    }
    @include respond("phone-land") {
      display: none;
    }
    &.sub-bar {
      font-size: 1.875rem;
    }
  }
  .search-bar {
    position: relative;
    &.show-result {
      .search-area-result {
        display: block !important;
      }
      .search-area {
        border-radius: 1rem !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
    }
    .search-area {
      width: 22rem;
      border-radius: 2rem;
      background-color: transparent;
      border: 1px solid #8798a9;
      padding: 0 .5rem;
      .search-icon{
        font-size: 1.2rem;
        padding-left: 0 !important;
        i{
          color: #8798a9;
        }
      }
      @include custommq($max: 100rem) {
        width: 22rem;
      }
      @include respond("phone") {
        // display: none;
        width: 15rem;

      }
      .form-control {
        border: 0;
        color: $primary !important;
        background: transparent;
        font-size: 1rem;
        font-weight: 400;
        padding-left: 4px;
        &::placeholder {
          color: #1b1c1d !important;
          opacity: 1;
        }
        @include respond("laptop") {
          height: 3rem;
          //font-size:13px;
        }
        &:focus{
          outline: none;
        }
      }
      .input-group-text {
        min-width: 2.125rem;
        background-color: transparent;
        border: 0;
        padding-right: 0;
        @include respond("laptop") {
          height: 3rem;
        }
        .ctrl-btn{
          margin-right: 3px;
          background-color: #c8d7e6;
          border-radius: 5px;
          padding: 0 3px;
          color: $primary;
        }
      }
      &:focus-within{
        border-color: $primary;
      }
      
    }

    .search-area-result {
      display: none;
      width: 22rem;
      position: fixed;
      padding: 0px 10px;
      margin-bottom: 1rem;
      max-height: 365px;
      background: #e5f1fd;
      border: 1px solid $primary;
      border-top: 0;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      overflow-y: hidden;
      overflow-x: hidden;
      @include custommq($max: 100rem) {
        width: 22rem;
      }
      @include respond("phone") {
        width: 15rem;
      }
      .search-area-result-item {
        margin: 1rem 0;
        &.selected{
          border: 2px solid $primary;
          border-radius: 4px;
          padding: 3px 4px;
          font-weight: 700;
        }
        span {
          color: $primary;
          font-size: 1rem;
          cursor: pointer;
          &:hover {
            font-weight: 700;
          }
        }
      }
      /* For WebKit Browsers (Chrome, Safari) */
      &::-webkit-scrollbar {
        width: 2px; /* Set the width of the scrollbar */
      }
      &::-webkit-scrollbar-thumb {
        background-color: $primary; /* Set the color of the thumb */
        border-radius: 10px; /* Set the border radius of the thumb */
      }
      &::-webkit-scrollbar-track {
        background-color: transparent; /* Set the color of the track */
      }
      /* Optional: Style the scrollbar buttons (arrows) */
      &::-webkit-scrollbar-button {
        background-color: transparent; /* Set the color of the buttons */
      }
      /* For Firefox */
      scrollbar-color: #888 #e5f1fd;
    }
  }
}
[data-headerbg="color_4"] {
  .search-area {
    background-color: transparent !important;
  }
}

[data-sidebar-style="compact"] {
  .header-left {
    margin-left: 0;
  }
}
