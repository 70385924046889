.nav-pills {
    .nav-link {
		border-radius:4px;
		padding: 5px 15px;
		font-size: 13px;
	}
}

.default-tab{
    .nav-link{
        background: transparent;
        border-top-left-radius: 0.75rem;
		border-top-right-radius: 0.75rem;
        font-weight: 500;
		i{
			display:inline-block;
			transform:scale(1.5);
			color:var(--primary);
			
		}
		
		&:focus,
		&:hover,
		&.active{
			color: #495057;
			background-color: #fff;
			border-color: #dee2e6 #dee2e6 #fff #ebeef6;
			border-radius: $radius $radius 0 0;
			color:var(--primary);
			@at-root [data-theme-version="dark"] & {
				background-color: var(--rgba-primary-1);
				border-color: transparent transparent $d-border transparent;
			}
		}
    }
}



.custom-tab-1{
	.nav-pills{
		&.light{
		
			.nav-link.active {
				background:transparent;
				color:var(--primary);
				box-shadow:none;
				@at-root [data-theme-version="dark"] & {
					background:var(--rgba-primary-1);
				}
			}
			.show > .nav-link{
				background:transparent;
				color:$body-color;
				box-shadow:none;
				@at-root [data-theme-version="dark"] & {
					background:var(--rgba-primary-1);
				}
			}	
		
		}
		.nav-link.active, 
		.show > .nav-link{
			background-color:transparent;
			box-shadow:0 0 1.25rem 0 var(--rgba-primary-2);
		}
		.custom-nav-dropdown{
			.nav-link{
				font-size: 1rem !important;
				font-weight: 600 !important;
			}
			&.active .nav-link{
				color: $primary !important;
				border-color: $primary !important;
				border-width: 0 0rem 0.1875rem 0 !important;
			}
		}
	}
    .nav-link{
        background: transparent;
        border-radius: 0rem;
        font-weight: 500;
		border-bottom:0.1875rem solid transparent;
		border-width: 0 0rem 0.1875rem 0;
		color: $body-color;
		i{
			display:inline-block;
			transform:scale(1.5);
			color:var(--primary);
		}
		&:focus,
		&:hover{
			color: $primary;
		}
		&.active{
			color: #495057;
			background-color: #fff;
			border-color:var(--primary) ;
			border-radius:0;
			color: var(--primary);
			border-width: 0 0rem 0.1875rem 0;
			@at-root [data-theme-version="dark"] & {
				background-color: var(--rgba-primary-1);
			}
		}
    }
	
}

.custom-btn-tab{
	.nav-pills{
		&.light{

			.nav-item{
				margin-right: 2rem;
				margin-bottom: 1rem;
			}
		
			.nav-link.active {
				background:linear-gradient(120deg, #a1c4fd 0%, #a1cee3 100%);;
				box-shadow:  0 4px 8px rgba(0, 0, 0, 0.1);
				color: white;
			}
			.show > .nav-link{
				background:linear-gradient(120deg, #a1c4fd 0%, #a1cee3 100%);;
				color:$body-color;
				box-shadow:  0 4px 8px rgba(0, 0, 0, 0.1);
			}	
		
		}
		.nav-link.active, 
		.show > .nav-link{
			background:linear-gradient(120deg, #a1c4fd 0%, #a1cee3 100%);
			box-shadow:  0 4px 8px rgba(0, 0, 0, 0.5);
			color: white;
		}
		.custom-nav-dropdown{
			.nav-link{
				font-size: 1rem !important;
				font-weight: 600 !important;
			}
			&.active .nav-link{
				color: white !important;
				border: .5px solid #797d7d;
			}
		}
	}
    .nav-link{
        background:linear-gradient(120deg, #a1c4fd 0%, #a1cee3 100%);
        border-radius: .4rem;
        font-weight: 500;
		border: .5px solid #797d7d;
		padding: 12px 30px;
		color: $body-color;
		i{
			display:inline-block;
			transform:scale(1.5);
			color:var(--primary);
		}
		&:focus,
		&:hover{
			color: $primary;
		}
    }
	
}


.nav-pills{
	
	&.light{
	
		.nav-link.active, 
		.show > .nav-link{
			background:var(--rgba-primary-1);
			color:var(--primary);
			box-shadow:none;
			@at-root [data-theme-version="dark"] & {
				background:var(--rgba-primary-1);
			}
		}	
	
	}
	.nav-link.active, 
	.show > .nav-link{
		background-color:var(--primary);
		box-shadow:0 0 1.25rem 0 var(--rgba-primary-2);
	}
}

