.c-pointer {
  cursor: pointer;
}
.react-select {
  .select__control {
    &.select__control--is-focused {
      border-color: $primary;
      box-shadow: none;
      border-width: 1px;
    }
  }
  &.is-invalid {
    .select__control {
      border-color: $danger !important;
    }
  }
}

.filter-search {
  input {
    border-right: 0px;
    &:focus {
      ~ .input-group-text {
        border-color: $primary;
      }
    }
  }
  .input-group-text {
    .filter-count {
      background-color: red;
      padding: 1px 5px;
      border-radius: 50px;
      color: white;
      position: absolute;
      margin: -5px 0 0 -7px;
      font-size: 0.6rem;
    }
  }
}

.tp-input-group {
  input {
    border-right: 0px;
    &:focus {
      border-color: $primary;
      outline-color: $primary;
      ~ .input-group-text {
        border-color: $primary;
      }
    }
  }
  .input-group-text {
    border-left: 0px;
    background-color: transparent;
  }
}

// styles.scss
.drawing-list-table {
  // Default overflow setting
  overflow: visible;
  // // Define mixin to toggle overflow
  // @mixin toggleOverflow($visible: false) {
  //   @if $visible {
  //     overflow: visible !important;
  //   } @else {
  //     overflow: auto;
  //   }
  // }
  // // Apply mixin when both .show and .dropdown classes are present on the same element
  // &.show.dropdown {
  //   @include toggleOverflow(true);
  // }

  thead {
    position: -webkit-sticky;
    position: sticky;
    top: 4.125rem;
  }
}
