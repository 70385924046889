.dropzone {
  &.dropzone-sm {
    height: 300px;
  }
  border: 2px dashed rgb(170, 170, 170);
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  height: 400px;
  overflow-y: auto;
  cursor: pointer;

  &.no-files {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .file-list {
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    .file-item {
      margin: 0.5rem 0.5rem;
      padding: 0.5rem 1rem;
      background-color: $primary-light;
      border-radius: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .file-name {
        font-size: 1.125rem;
        color: $primary;
        small {
          font-size: 0.6rem;
          font-weight: 500;
        }
      }
      .file-remove {
        margin-left: 2rem;
        // background-color: $danger;
        // color: $white;
        // width: 1.5rem;
        // height: 1.5rem;
        // text-align: center;
        // border-radius: 50%;
        // line-height: 1.5rem;
        font-size: 1.2rem;
        cursor: pointer;
      }
    }
  }
}
