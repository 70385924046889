.footer {
  font-size: 0.813rem;
  font-weight: 400;
  width: 100%;
  background: $primary-light;
  position: absolute;
  bottom: -12px;
  .copyright {
    p {
      text-align: center;
      margin: 0;
      color: #918f8f;
    }
    a {
      color: var(--primary) !important;
    }
  }
}
