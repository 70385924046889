.home-containter{
	margin: 0;
	padding: 0;
	height: 100vh !important;
	background-blend-mode: multiply;
	background-size: cover;
	background-position: center;
	overflow: hidden !important;
	.header-logo{
		height: 120px;
		// d-flex align-itens-center justify-content-between my-4 p-2 bg-white rounded
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: $white;
		border-bottom-left-radius: 0.5rem;
		border-bottom-right-radius: 0.5rem;
		padding: 0 10px;
	}
	.main-content{
		height: calc(100vh - 150px);
		overflow-y: auto;
		overflow-x: hidden !important;
		padding: 20px 10px 10px 10px;
		.main-content-row{
			flex:1 1 auto !important;
			align-items: center;
			.slider-containter{
				.carousel .carousel-item img{
					height: calc(100vh - 190px) !important;
					object-fit: cover;
					width: 100%;
				}
			}
		}
	}
	.home-footer{
		height: 30px !important;
		.marquee-text{
			background: #1b1b1b80;
			color:#f8e903;
			font-size: 1.5rem;
			height: 100%;
		}
	}
}


.logo-header {
    margin-bottom: 2rem;
	.logo-white {
		margin-left: 0.625rem;
	}
}

.login-form .forget-pass {
    display: block;
    margin-top: 20px;
    text-align: center;
	
}
.dz-social {
    display: flex;
    justify-content: space-between;
}
.dz-social-icon {
    display: inline-flex;
    margin: 0 -3px;
    padding: 0;
    text-align: center;
	li{ 
		font-size: 12px;
		margin-right: 0.2rem;
		a {
			color: #ffffff;
			font-size: 14px;
			height: 35px;
			line-height: 34px;
			padding: 0;
			width: 35px;
			border-radius: 4px;
		}
	}
}
.bg-img-fix{
	&:before{
}
}
div#mCSB_1 {
    margin-top: 100px;
}


// for print new user request
.print-area{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.print-row{
		margin-top: 2rem;
		table td, th {
			border: 1px solid black;
			padding: 10px 15px;
		}
	}
}