
  .bottom-nav{
    position: fixed;
    bottom:0px;
    width: 100%;
    height: 50px;
    background-color:$primary;
    nav{
      display:flex;
      align-items: center;
      justify-content: space-around;
      height:100%;
    }
  .menu {
    span svg{
      fill: white;
      width: 25px;
      height:25px;
    }
    &.active {
          width: 40px;
          background: #e5f1fd;
          text-align: center;
          padding: 5px 0;
          height: 35px;
          border-radius: 10px;
          box-shadow: 0px 1px #d1c3c3;
          span svg{
            fill: $primary;
          }
    }
  }
}
  

